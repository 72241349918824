import Homepage from './containers/Homepage/Homepage';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ['Jura'].join(','),
    },
  });
  return (
    <ThemeProvider theme={theme}>
      {/* Use a styled engine provider to inject MUI styles first, so we don't have to use !important everywhere */}
      <StyledEngineProvider injectFirst>
        <Homepage />
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
