import { Button, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import styles from './FooterSection.module.scss';
import commonStyles from './../../utilities/CommonStyles.module.scss';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export interface IconProperties {
  icon: ReactNode;
  link: string;
  alt: string;
}

const FooterSection: FC = () => {
  const icons: IconProperties[] = [
    {
      icon: <LinkedInIcon />,
      link: 'https://www.linkedin.com/in/jason-yao-s/',
      alt: 'linkedin',
    },
    {
      icon: <GitHubIcon />,
      link: 'https://github.com/rayoya',
      alt: 'github',
    },
    {
      icon: <MailOutlineIcon />,
      link: 'mailto:jyao4133@gmail.com',
      alt: 'email',
    },
  ];
  return (
    <div className={styles.Footer__container} id="Footer">
      <Typography className={[commonStyles.ExplanationText, styles.Quote__container].join(' ')}>
        <strong>"You can never cross the ocean until you have the courage to lose sight of the shore"</strong>
      </Typography>
      <div className={styles.SocialMedia__container}>
        {icons.map((icon: IconProperties) => (
          <Button href={icon.link} className={styles.SocialMediaButton} download>
            {icon.icon}
          </Button>
        ))}
      </div>
      <Button className={styles.BackToTopButton} onClick={() => document.getElementById('Topofpage')?.scrollIntoView()}>
        Back to top
      </Button>
    </div>
  );
};

export default FooterSection;
