import { FC } from 'react';
import { Typography } from '@mui/material';
import styles from './AboutMe.module.scss';
import commonStyles from './../../utilities/CommonStyles.module.scss';
import SkillSection from '../../components/SkillSection/SkillSection';
import { backEndLanguages, backEndTools, frontEndLanguages, frontEndTools } from '../../utilities/constants';

const AboutMe: FC = () => (
  <div className={styles.Section__container} id="AboutMe">
    <div className={styles.AboutMe__container}>
      <div className={styles.AboutMe__textContainer}>
        <Typography
          className={[commonStyles.SubheadingText, commonStyles.WhiteText, styles.AboutMe__subHeadingText].join(' ')}
        >
          About me
        </Typography>
        <Typography className={[commonStyles.DescriptionText, commonStyles.WhiteText].join(' ')}>
          Hiya! I&apos;m Jason and I&apos;m a Full Stack Engineer located in Sydney, Australia. I have a passion for
          creating engaging and clean experiences, as well as being part of that journey from start to finish.
        </Typography>
      </div>
    </div>

    <div className={styles.SkillSection__container}>
      <SkillSection isLeftBox languages={frontEndLanguages} tools={frontEndTools} />
      <SkillSection isLeftBox={false} languages={backEndLanguages} tools={backEndTools} />
    </div>
  </div>
);

export default AboutMe;
