import { Typography } from '@mui/material';
import { FC } from 'react';
import commonStyles from './../../utilities/CommonStyles.module.scss';
import fbLogo from '../../logos/foodbomb_logo.png';
import mmoParty from '../../projects/MMOParty.png';
import projectSwipe from '../../projects/ProjectSwipe.png';
import shopForge from '../../projects/ShopForge.png';
import styles from './Work.module.scss';
import ProjectCard from '../../components/ProjectCard/ProjectCard';

interface LogoProperties {
  source: string;
  alt: string;
}

export interface ProjectCardProperties {
  linkToProject?: string;
  cardBackgroundImage: string;
  inProgress: boolean;
  description: string;
  title: string;
}

const companyLogos: LogoProperties[] = [
  {
    source: fbLogo,
    alt: 'foodbomb-logo',
  },
];

const projects: ProjectCardProperties[] = [
  {
    cardBackgroundImage: projectSwipe,
    inProgress: true,
    description: 'A React Native app for rewarding frequent customers.',
    title: 'Swipe',
  },
  {
    cardBackgroundImage: mmoParty,
    inProgress: true,
    description: 'A Discord bot to allows users to create parties.',
    title: 'MMOParty',
  },
  {
    cardBackgroundImage: shopForge,
    inProgress: true,
    description: 'An E-Commerce creation company.',
    title: 'ShopForge',
  },
];

const Work: FC = () => (
  <div className={styles.Work__container} id="Work">
    <Typography className={[commonStyles.SubheadingText, styles.Work__subHeadingText].join(' ')}>Work</Typography>
    <Typography className={[commonStyles.DescriptionText, styles.ExtraPadding].join(' ')}>
      Here are some awesome companies that I&apos;ve worked at:
    </Typography>
    <div className={styles.CompanyLogo_container}>
      {companyLogos.map((logo: LogoProperties) => (
        <img className={styles.Logo} src={logo.source} alt={logo.alt} />
      ))}
    </div>
    <Typography className={[commonStyles.DescriptionText, styles.ExtraPadding].join(' ')}>
      And here&apos;s some cool stuff that I work on in my spare time:
    </Typography>
    <div className={styles.Projects__container}>
      {projects.map((project: ProjectCardProperties) => (
        <ProjectCard
          title={project.title}
          description={project.description}
          cardBackgroundImage={project.cardBackgroundImage}
          inProgress={project.inProgress}
        />
      ))}
    </div>
  </div>
);

export default Work;
