import {
  AppBar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import CustomButton from '../../components/CustomButton/CustomButton';
import styles from './Homepage.module.scss';
import commonStyles from './../../utilities/CommonStyles.module.scss';
import AboutMe from '../AboutMe/AboutMe';
import Work from '../Work/Work';
import ContactMe from '../ContactMe/ContactMe';
import FooterSection from '../FooterSection/FooterSection';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';

interface ButtonData {
  buttonText: string;
  id: string;
}

const buttonData: ButtonData[] = [
  {
    buttonText: 'About',
    id: 'AboutMe',
  },
  {
    buttonText: 'Work',
    id: 'Work',
  },
  {
    buttonText: 'Contact',
    id: 'Contact',
  },
];

const Homepage = () => {
  const [mobileDrawerOpen, setMobileDrawOpen] = useState<boolean>(false);

  const closeMobileDrawer = () => {
    setMobileDrawOpen(false);
  };

  const handleItemClicked = (id: string) => {
    setMobileDrawOpen(false);
    document.getElementById(id)?.scrollIntoView();
  };

  return (
    <div className={styles.Homepage__container}>
      <AppBar position="sticky" className={styles.NavBar}>
        <Toolbar className={styles.WindowsToolbar}>
          <div className={styles.NavBar__buttonContainer}>
            {buttonData.map((data: ButtonData) => (
              <CustomButton buttonText={data.buttonText} id={data.id} />
            ))}
          </div>
        </Toolbar>
        <Toolbar className={styles.MobileToolbar} classes={{ root: styles.NoPadding }}>
          <Button className={styles.HamburgerMenuButton} onClick={() => setMobileDrawOpen(true)}>
            <MenuIcon />
          </Button>
        </Toolbar>
        <Drawer
          open={mobileDrawerOpen}
          onClose={closeMobileDrawer}
          classes={{ paper: styles.MobileDrawer }}
          variant="temporary"
        >
          <List>
            {buttonData.map((data: ButtonData) => (
              <ListItem key={data.buttonText} disablePadding>
                <ListItemButton onClick={() => handleItemClicked(data.id)} sx={{ textAlign: 'center' }}>
                  <ListItemText primary={data.buttonText} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </AppBar>

      <div className={styles.Homepage__heading} id="Topofpage">
        <div className={styles.Homepage__headingText}>
          <Typography className={styles.HeadingText}>Jason Yao</Typography>
          <Typography className={commonStyles.SubheadingText}>Full Stack Engineer</Typography>
          <Typography className={commonStyles.DescriptionText}>
            I love solving problems and exploring new technologies
          </Typography>
        </div>
      </div>
      <AboutMe />
      <Work />
      <ContactMe />
      <FooterSection />
    </div>
  );
};

export default Homepage;
