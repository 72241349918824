import { Typography } from '@mui/material';
import { FC } from 'react';
import { ProjectCardProperties } from '../../containers/Work/Work';
import styles from './ProjectCard.module.scss';

const ProjectCard: FC<ProjectCardProperties> = ({
  title,
  description,
  inProgress,
  cardBackgroundImage,
  linkToProject,
}) => (
  <figure className={styles.Card__container}>
    <img src={cardBackgroundImage} alt={title} className={styles.Card__image} />
    <div className={styles.Overlay}>
      <Typography className={styles.Card__textTop}>
        <strong>{title} </strong>
      </Typography>
      <Typography className={styles.Card__textCenter}>{description}</Typography>
    </div>
  </figure>
);

export default ProjectCard;
