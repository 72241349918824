// Frontend details
export const frontEndLanguages = ['Typescript', 'Javascript', 'CSS', 'HTML'];
export const frontEndTools = [
  'Functional React',
  'Class Based React',
  'React Native',
  'Redux',
  'Auth0',
  'Jest/Mocha',
  'SASS',
];

// Backend details
export const backEndLanguages = ['PHP', 'Java', 'Typescript', 'Javascript', 'SQL'];
export const backEndTools = ['Laravel', 'NodeJS', 'MySQL', 'PostgreSQL', 'AWS Cloud', 'Spring Boot'];
