import Typography from '@mui/material/Typography';
import { FC } from 'react';
import styles from './SkillSection.module.scss';
import commonStyles from './../../utilities/CommonStyles.module.scss';

interface SkillSectionProps {
  isLeftBox: boolean;
  languages: string[];
  tools: string[];
}

const SkillSection: FC<SkillSectionProps> = ({ isLeftBox, languages, tools }) => (
  <div
    className={isLeftBox ? styles.SkillSection_container : [styles.SkillSection_container, styles.RightBox].join(' ')}
  >
    <Typography className={commonStyles.SubheadingText}>{isLeftBox ? 'Front End' : 'Back End'}</Typography>
    <div className={styles.ExplanationText__container}>
      <Typography className={commonStyles.ExplanationText}>
        {isLeftBox ? (
          <>
            Bringing a design to life is at the <strong>front</strong> of my list of passions
          </>
        ) : (
          <>
            Creating fast, efficient and robust services brings me <strong>back</strong>
          </>
        )}
      </Typography>
    </div>
    <Typography className={styles.SkillSection__heading}>
      <strong>Languages</strong>
    </Typography>
    <div className={styles.Language__items}>
      {languages.map((language: string) => (
        <Typography key={`lanuage-${language}`}>{language}</Typography>
      ))}
    </div>
    <Typography className={styles.SkillSection__heading}>
      <strong>Tools and Framework</strong>
    </Typography>
    <div className={styles.Tool__items}>
      {tools.map((tool: string) => (
        <Typography key={`lanuage-${tool}`}>{tool}</Typography>
      ))}
    </div>
  </div>
);

export default SkillSection;
