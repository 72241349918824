import Typography from '@mui/material/Typography';
import { FC } from 'react';
import styles from './ContactMe.module.scss';
import commonStyles from './../../utilities/CommonStyles.module.scss';
import { Button } from '@mui/material';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';

const ContactMe: FC = () => (
  <div className={styles.Section__container} id="Contact">
    <Typography className={[commonStyles.SubheadingText, styles.ExtraMargin].join(' ')}>
      <strong>Contact me</strong>
    </Typography>
    <div className={styles.ContactMe__container}>
      <Typography className={[styles.ContactText, styles.DescriptionStyling].join(' ')}>
        I’m always looking for new problems to solve and cool things to create! If you&apos;d like to have a chat in
        real life, the coffee is on me!
      </Typography>
      <Button className={styles.ButtonStyling} href="mailto:jyao4133@gmail.com" endIcon={<LocalCafeOutlinedIcon />}>
        Say Hi
      </Button>
    </div>
  </div>
);

export default ContactMe;
