import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './CustomButton.module.scss';

interface CustomButtonProps {
  buttonText: string;
  id: string;
}

const CustomButton: FC<CustomButtonProps> = ({ buttonText, id }) => (
  <Button
    className={styles.ButtonStyling}
    onClick={() => document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })}
  >
    <Typography className={styles.ButtonText}>{buttonText}</Typography>
  </Button>
);

export default CustomButton;
